<template>
  <div class="card card-custom">
    <div class="card-header">
      <div class="card-title">{{ $t("insights.edit") }}</div>
      <div class="card-toolbar">
        <button class="btn btn-secondary" @click="cancel">
          {{ $t("general.cancel") }}
        </button>
      </div>
    </div>
    <v-progress-linear v-if="isBusy" indeterminate color="primary" />
    <div v-if="isReady" class="card-body p-0">
      <InsightsWizard
        :insight="insight"
        @save="save"
      />
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import InsightsWizard from "@/components/Projects/Insights/Wizard/Index";
import Insights from "@/components/Projects/Insights/insights";

import { structure } from "@/components/Projects/Insights/structure";

export default {
  components: { InsightsWizard },
  props: {},
  data() {
    return {
      isReady: false,
      insight: structure,
      isBusy: false
    };
  },
  mounted() {
    this.setBreadcrumbs();
    this.loadInsight();
  },
  methods: {
    setBreadcrumbs() {
      this.$store.dispatch(SET_BREADCRUMB, [
        {
          title: this.$t("insights.title"),
          route: "/project/insights"
        },
        {
          title: this.$t("insights.edit"),
          route: "/project/insights/edit"
        }
      ]);
    },
    cancel() {
      this.$router.push({ name: "projectInsights" });
    },
    loadInsight() {
      let id = this.$route.params.id;
      this.isBusy = true;
      Insights.get(id)
        .then(response => {
          const _ = require("lodash");
          this.insight = _.merge(structure, response.data.data);
          this.isReady = true;
          this.isBusy = false;
        })
        .catch(error => {
          this.$swal.fire({
            icon: "error",
            title: this.$t("general.caution"),
            text: error.response?.data?.message
          });
        });
    },
    save(payload) {
      this.isBusy = true;
      Insights.update(payload.data.id, payload.data)
        .then(() => {
          this.isBusy = false;
          payload.success();
        })
        .catch(error => {
          payload.error(error);
        });
    }
  }
};
</script>

<template>
  <div>
    <div class="mb-3">
      <label>
        {{ $t("insights.pieCategory") }}
      </label>
      <v-select
        v-model="$v.graph.x.$model"
        :disabled="!graph.dataSet"
        :items="dataSetFields"
        :item-text="f => f.name + ` (${f.label} - ${f.description})`"
        item-value="name"
        :placeholder="$t('insights.pieCategory')"
        :menu-props="{ offsetY: true }"
        class="form-control"
        :class="{ 'is-invalid': $v.graph.x.$error }"
        :no-data-text="$t('insights.graphAxisNoFields')"
      />
      <span v-if="!graph.dataSet" class="text-caption text-dark-50">
        {{ $t("insights.graphAxisHint") }}
      </span>
    </div>
    <div class="mb-3">
      <label>
        {{ $t("insights.pieValue") }}
      </label>
      <v-select
        v-model="$v.graph.y.$model"
        :disabled="!graph.dataSet"
        :items="dataSetFields"
        :item-text="f => f.name + ` (${f.label})`"
        item-value="name"
        :placeholder="$t('insights.pieValue')"
        :menu-props="{ offsetY: true }"
        class="form-control"
        :class="{ 'is-invalid': $v.graph.y.$error }"
        :no-data-text="$t('insights.graphAxisNoFields')"
      />
      <span v-if="!graph.dataSet" class="text-caption text-dark-50">
        {{ $t("insights.graphAxisHint") }}
      </span>
    </div>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";

export default {
  name: "PieConfig",
  props: {
    graph: {
      type: Object,
      default: () => {}
    },
    config: {
      type: Object,
      default: () => {}
    },
    dataSetFields: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {};
  },
  validations() {
    return {
      graph: {
        x: { required },
        y: { required }
      }
    };
  },
  mounted() {},
  methods: {
    isReady() {
      this.$v.$touch();
      return !this.$v.$invalid;
    }
  }
};
</script>

<template>
  <div>
    <!------------ START: GENERAL OPTIONS ---------->
    <div class="card border-right-0 border-left-0">
      <div class="card-header bg-white">
        <div v-b-toggle="'collapseGraphs'" class="card-title">
          {{ $t("insights.customizeGeneral") }}
        </div>
      </div>
      <b-collapse id="collapseGraphs" accordion="element-accordion">
        <div class="card-body">
          <div class="mb-3">
            <label>
              {{ $t("insights.chartTitle") }}
            </label>
            <input
              v-model="config.title.text"
              :placeholder="$t('insights.chartTitle')"
              type="text"
              class="form-control"
              @change="updateChart"
            />
          </div>
          <div class="mb-3">
            <label>
              {{ $t("insights.stacked") }}
            </label>
            <span class="switch switch-sm">
              <label>
                <input
                  v-model="config.chart.stacked"
                  type="checkbox"
                  @change="updateChart(true)"
                />
                <span></span>
              </label>
            </span>
          </div>
          <div class="mb-3">
            <label>
              {{ $t("insights.stackType") }}
            </label>
            <v-select
              v-model="config.chart.stackType"
              :items="stackTypes"
              :placeholder="$t('insights.stackType')"
              :menu-props="{ offsetY: true }"
              class="form-control"
              @change="updateChart(true)"
            />
          </div>
          <div class="mb-3">
            <label>
              {{ $t("insights.theme") }}
            </label>
            <v-select
              v-model="config.theme.palette"
              :items="palettes"
              :placeholder="$t('insights.theme')"
              :menu-props="{ offsetY: true }"
              class="form-control"
              @change="updateChart"
            />
          </div>
        </div>
      </b-collapse>
    </div>
    <!------------ END: GENERAL OPTIONS ---------->
    <!------------ START: LINE OPTIONS ---------->
    <div class="card border-right-0 border-left-0">
      <div class="card-header bg-white">
        <div v-b-toggle="'collapseLine'" class="card-title">
          {{ $t("insights.customizeGraphs") }}
        </div>
      </div>
      <b-collapse id="collapseLine" accordion="element-accordion">
        <div class="card-body">
          <div class="mb-3">
            <label>
              {{ $t("insights.curve") }}
            </label>
            <v-select
              v-model="config.stroke.curve"
              :items="curves"
              :placeholder="$t('insights.curve')"
              :menu-props="{ offsetY: true }"
              class="form-control"
              @change="updateChart"
            />
          </div>
          <div class="mb-3">
            <label>
              {{ $t("insights.showDataLabels") }}
            </label>
            <span class="switch switch-sm">
              <label>
                <input
                  v-model="config.dataLabels.enabled"
                  type="checkbox"
                  @change="updateChart"
                />
                <span></span>
              </label>
            </span>
          </div>
          <div class="mb-3">
            <label>
              {{ $t("insights.showMarkers") }}
            </label>
            <span class="switch switch-sm">
              <label>
                <input
                  v-model="showMarkers"
                  type="checkbox"
                  @change="updateChart"
                />
                <span></span>
              </label>
            </span>
          </div>
        </div>
      </b-collapse>
    </div>
    <!------------ END: LINE OPTIONS ---------->
    <!------------ START: X AXIS ---------->
    <div class="card border-right-0 border-left-0 border-top-0">
      <div class="card-header bg-white">
        <div v-b-toggle="'collapseX'" class="card-title">
          {{ $t("insights.customizeAxisX") }}
        </div>
      </div>
      <b-collapse id="collapseX" accordion="element-accordion">
        <div class="card-body">
          <div class="mb-3">
            <label>
              {{ $t("insights.xAxisTitle") }}
            </label>
            <input
              v-model="config.xaxis.title.text"
              :placeholder="$t('insights.xAxisTitle')"
              type="text"
              class="form-control"
              @change="updateChart"
            />
          </div>
        </div>
      </b-collapse>
    </div>
    <!------------ END: X AXIS ---------->
    <!------------ START: Y AXIS ---------->
    <div class="card border-right-0 border-left-0 border-top-0">
      <div class="card-header bg-white">
        <div v-b-toggle="'collapseY'" class="card-title">
          {{ $t("insights.customizeAxisY") }}
        </div>
      </div>
      <b-collapse id="collapseY" accordion="element-accordion">
        <div class="card-body">
          <div class="mb-3">
            <label>
              {{ $t("insights.yAxisTitle") }}
            </label>
            <input
              v-model="yaxisTitle"
              :placeholder="$t('insights.yAxisTitle')"
              type="text"
              class="form-control"
              @change="updateChart"
            />
          </div>
        </div>
      </b-collapse>
    </div>
    <!------------ END: Y AXIS ---------->
  </div>
</template>

<script>
export default {
  name: "LineCustomization",
  props: {
    config: {
      type: Object,
      default: () => {}
    },
    palettes: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      curves: [
        {
          text: this.$t("insights.curveSmooth"),
          value: "smooth"
        },
        {
          text: this.$t("insights.curveStraight"),
          value: "straight"
        },
        {
          text: this.$t("insights.curveStepLine"),
          value: "stepline"
        }
      ],
      stackTypes: [
        {
          text: this.$t("insights.stackTypeAbsolute"),
          value: "normal"
        },
        {
          text: this.$t("insights.stackTypeRelative"),
          value: "100%"
        }
      ]
    };
  },
  computed: {
    yaxisTitle: {
      get: function () {
        return this.config?.yaxis?.[0]?.title?.text ?? "";
      },
      set: function (value) {
        this.config.yaxis[0].title.text = value;
      }
    },
    showMarkers: {
      get: function () {
        return !!this.config.markers.size;
      },
      set: function (show) {
        this.config.markers.size = show ? 5 : 0;
      }
    }
  },
  mounted() {
    this.checkFieldsAvailable();
  },
  methods: {
    updateChart(stackOptionChanged = false) {
      this.$emit("update-chart", stackOptionChanged);
    },
    checkFieldsAvailable() {
      if (this.config?.yaxis?.[0]?.title?.text === undefined) {
        this.config.yaxis[0].title.text = "";
      }
      if (this.config?.xaxis?.[0]?.title?.text === undefined) {
        this.config.yaxis[0].title.text = "";
      }
    }
  }
};
</script>

export const structure = {
  label: "",
  config: {
    chart: {
      type: "",
      stacked: false,
      stackType: "normal"
    },
    dataLabels: {
      enabled: true
    },
    markers: {
      size: 0
    },
    theme: {
      palette: "palette1"
    },
    title: {
      text: ""
    },
    xaxis: {
      labels: {
        format: ""
      },
      title: {
        text: ""
      }
    },
    yaxis: {
      title: {
        text: ""
      }
    },
    stroke: {
      curve: "smooth"
    },
    plotOptions: {
      bar: {
        horizontal: false,
        dataLabels: {
          position: "top"
        }
      },
      pie: {
        startAngle: 0,
        endAngle: 360,
        donut: {
          size: 65
        }
      }
    }
  },
  insightGraphs: []
};

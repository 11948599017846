import ApiService from "@/core/services/api.service";
import store from "@/core/services/store";

let url = process.env.VUE_APP_API_TENANT;
if (process.env.VUE_APP_VERSION >= 2) {
  url = process.env.VUE_APP_API_ADMIN;
}

ApiService.setHeader("x-api-key", store.getters.apiToken);

class DataPipelines {
  get(id) {
    return ApiService.get(url, "data_pipelines/" + id);
  }

  getAll(params = { page: 1, size: 10 }, cancelToken) {
    let options = new URLSearchParams(params).toString();
    let headers = {};
    if (cancelToken) {
      headers.cancelToken = cancelToken;
    }
    return ApiService.get(url, "data_pipelines?" + options, headers);
  }

  store(data) {
    return ApiService.post(url + "/data_pipelines", data);
  }

  update(id, data) {
    return ApiService.put(url + "/data_pipelines/" + id, data);
  }

  delete(id) {
    return ApiService.delete(url + "/data_pipelines/" + id);
  }

  run(data) {
    return ApiService.post(url + "/data_pipelines/run", data);
  }

  getConfig() {
    return ApiService.get(url, "data_pipelines/config");
  }

  fields(id) {
    return ApiService.get(url, "data_pipelines/" + id + "/fields");
  }
}

export default new DataPipelines();

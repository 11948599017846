<template>
  <div class="card card-custom">
    <div class="card-header">
      <div class="card-title">{{ $t("insights.edit") }}</div>
      <div class="card-toolbar">
        <button class="btn btn-secondary" @click="back">
          {{ $t("general.close") }}
        </button>
      </div>
    </div>
    <v-progress-linear v-if="isBusy" indeterminate color="primary" />
    <div v-if="isReady" class="card-body">
      <apexchart ref="chart" :options="insight.config" :series="series" />
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Insights from "@/components/Projects/Insights/insights";

export default {
  components: {},
  props: {},
  data() {
    return {
      isReady: false,
      insight: undefined,
      series: [],
      isBusy: false
    };
  },
  mounted() {
    this.setBreadcrumbs();
    this.loadInsight();
  },
  methods: {
    setBreadcrumbs() {
      this.$store.dispatch(SET_BREADCRUMB, [
        {
          title: this.$t("insights.title"),
          route: "/project/insights/"
        },
        {
          title: this.$t("insights.show"),
          route: "/project/insights/show"
        }
      ]);
    },
    back() {
      this.$router.push({ name: "projectInsights" });
    },
    loadInsight() {
      let id = this.$route.params.id;
      this.isBusy = true;
      Insights.get(id)
        .then(response => {
          this.insight = response.data.data;
          this.loadData();
        })
        .catch(error => {
          this.$swal.fire({
            icon: "error",
            title: this.$t("general.caution"),
            text: error.response?.data?.message
          });
        });
    },
    loadData() {
      this.isBusy = true;
      Insights.run(this.insight.id)
        .then(response => {
          this.series = response.data.data.series;

          /*++++++++++++++++ Zwischenlösung bis Core angepasst ++++++++++++++++*/
          if (["pie", "donut"].includes(this.insight.config.chart.type)) {
            let series = [];
            let labels = [];
            this.series[0].data.forEach(data => {
              labels.push(data.x);
              series.push(data.y);
            });
            this.series = series;
            this.insight.config.labels = labels;
          }
          /*++++++++++++++++  ++++++++++++++++* ++++++++++++++++*/

          this.isBusy = false;
          this.isReady = true;
          console.log(this.series)
        })
        .catch(error => {
          this.$swal.fire({
            icon: "error",
            title: this.$t("general.caution"),
            text: error.response?.data?.message
          });
        });
    }
  }
};
</script>

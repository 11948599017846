<template>
  <div id="kt_wizard_insights" class="wizard wizard-2">
    <!--begin: Wizard Nav-->
    <div class="wizard-nav border-right py-8 px-8 py-lg-20 px-lg-10">
      <!--begin::Wizard Steps-->
      <div class="wizard-steps">
        <div
          v-for="(step, i) in steps"
          :key="i"
          class="wizard-step"
          data-wizard-type="step"
        >
          <div class="wizard-wrapper align-items-center">
            <div class="wizard-icon" style="width: 24px">
              <i class="icon-lg" :class="step.icon" />
            </div>
            <div class="wizard-label">
              <h3 class="wizard-title mb-0">{{ step.label }}</h3>
            </div>
          </div>
        </div>
      </div>
      <!--end::Wizard Steps-->
    </div>
    <!--end: Wizard Nav-->
    <!--begin: Wizard Body-->
    <div class="wizard-body my-10 px-8 mb-lg-15 px-lg-10">
      <!--begin: Wizard Steps-->
      <div class="col-xl-12 col-xxl-12">
        <div data-wizard-type="step-content">
          <Type ref="step-type" :data="data" />
        </div>
        <div data-wizard-type="step-content">
          <Configuration ref="step-configuration" :data="data" />
        </div>
        <div data-wizard-type="step-content">
          <Customization ref="step-customization" :data="data" />
        </div>
      </div>
      <!--end: Wizard Steps-->
      <!--begin: Wizard Actions-->
      <div class="d-flex justify-content-between border-top mt-5 pt-10">
        <div class="mr-2">
          <button
            type="button"
            class="btn btn-light-primary font-weight-bolder text-uppercase px-9 py-4"
            data-wizard-type="action-prev"
            :disabled="isBusy"
          >
            {{ $t("general.previousStep") }}
          </button>
        </div>
        <div>
          <button
            type="button"
            class="btn btn-primary font-weight-bolder text-uppercase px-9 py-4"
            data-wizard-type="action-submit"
            :disabled="isBusy"
            @click="nextStep"
          >
            {{ $t("insights.finish") }}
          </button>
          <button
            v-if="wizardStep === 2"
            type="button"
            class="btn btn-primary font-weight-bolder text-uppercase px-9 py-4"
            data-wizard-type="action-next"
            :disabled="isBusy"
            @click.prevent="nextStep"
          >
            {{ $t("insights.saveAndContinue") }}
          </button>
          <button
            v-else
            type="button"
            class="btn btn-primary font-weight-bolder text-uppercase px-9 py-4"
            data-wizard-type="action-next"
            :disabled="isBusy"
            @click.prevent="nextStep"
          >
            {{ $t("general.nextStep") }}
          </button>
        </div>
      </div>
      <!--end: Wizard Actions-->
    </div>
    <!--end: Wizard Body-->
  </div>
</template>

<script>
import KTWizard from "@/assets/js/components/wizard";
import Type from "@/components/Projects/Insights/Wizard/Steps/Type";
import Configuration from "@/components/Projects/Insights/Wizard/Steps/Configuration";
import Customization from "@/components/Projects/Insights/Wizard/Steps/Customization";

import { structure } from "@/components/Projects/Insights/structure";

export default {
  name: "InsightsWizard",
  components: { Customization, Configuration, Type },
  props: {
    insight: {
      type: Object,
      default: () => {}
    },
    isEdit: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      wizard: undefined,
      isBusy: false,
      data: structure,
      steps: [
        {
          name: "type",
          label: this.$t("insights.selectType"),
          icon: "fal fa-chart-simple"
        },
        {
          name: "configuration",
          label: this.$t("insights.configuration"),
          icon: "fal fa-gear"
        },
        {
          name: "customization",
          label: this.$t("insights.customization"),
          icon: "fal fa-paint-brush"
        }
      ]
    };
  },
  computed: {
    wizardStep: function () {
      return this.wizard ? this.wizard.getStep() : 1;
    }
  },
  mounted() {
    const _ = require("lodash");
    let copy = JSON.parse(JSON.stringify(this.insight));
    this.data = _.merge(structure, copy);
    this.initializeWizard();
  },
  methods: {
    initializeWizard() {
      this.wizard = new KTWizard("kt_wizard_insights", {
        clickableSteps: false,
        startStep: 1
      });
    },
    nextStep() {
      this.wizard.stop();
      let currentStep = this.wizard.getStep();
      let stepName = "step-" + this.steps[currentStep - 1].name;
      let isReady = this.$refs[stepName].isReady();
      if (!isReady) {
        return;
      }
      if (["step-configuration", "step-customization"].includes(stepName)) {
        this.emitSave();
      } else {
        this.wizard.resume();
      }
    },
    emitSave() {
      this.$emit("save", {
        data: this.data,
        nextStep: this.wizard.getStep() + 1,
        success: this.emitSuccessResponse,
        error: this.emitErrorResponse
      });
    },
    emitSuccessResponse(id = undefined) {
      if (id) {
        this.$set(this.data, "id", id);
      }
      this.wizard.resume();
      let currentStep = this.wizard.getStep();
      let stepName = "step-" + this.steps[currentStep - 1].name;
      if (stepName === "step-customization") {
        this.$router.push({ name: "projectInsights" });
      } else {
        this.$refs["step-customization"].getData();
        this.wizard.goNext();
      }
    },
    emitErrorResponse(error) {
      this.$swal.fire({
        icon: "error",
        title: this.$t("general.caution"),
        text: error.response?.data?.message
      });
    }
  }
};
</script>

<style lang="scss">
@import "../../../../assets/sass/pages/wizard/wizard-2";

#kt_wizard_insights_ {
  .wizard-icon {
    font-size: 1rem;
  }
}
</style>

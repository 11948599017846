<template>
  <div>
    <!------------ START: GENERAL OPTIONS ---------->
    <div class="card border-right-0 border-left-0">
      <div class="card-header bg-white">
        <div v-b-toggle="'collapseGraphs'" class="card-title">
          {{ $t("insights.customizeGeneral") }}
        </div>
      </div>
      <b-collapse id="collapseGraphs" accordion="element-accordion">
        <div class="card-body">
          <div class="mb-3">
            <label>
              {{ $t("insights.chartTitle") }}
            </label>
            <input
              v-model="config.title.text"
              :placeholder="$t('insights.chartTitle')"
              type="text"
              class="form-control"
              @change="updateChart"
            />
          </div>
          <div class="mb-3">
            <label>
              {{ $t("insights.isDonut") }}
            </label>
            <span class="switch switch-sm">
              <label>
                <input
                  v-model="isDonut"
                  type="checkbox"
                  @change="updateChart"
                />
                <span></span>
              </label>
            </span>
          </div>
          <div class="mb-3">
            <label>
              {{ $t("insights.theme") }}
            </label>
            <v-select
              v-model="config.theme.palette"
              :items="palettes"
              :placeholder="$t('insights.theme')"
              :menu-props="{ offsetY: true }"
              class="form-control"
              @change="updateChart"
            />
          </div>
        </div>
      </b-collapse>
    </div>
    <!------------ END: GENERAL OPTIONS ---------->
    <!------------ START: PIE OPTIONS ---------->
    <div class="card border-right-0 border-left-0 border-top-0">
      <div class="card-header bg-white">
        <div v-b-toggle="'collapsePie'" class="card-title">
          {{ $t("insights.customizePie") }}
        </div>
      </div>
      <b-collapse id="collapsePie" accordion="element-accordion">
        <div class="card-body">
          <div class="mb-3">
            <label>
              {{ $t("insights.startAngle") }}
            </label>
            <input
              v-model="config.plotOptions.pie.startAngle"
              type="number"
              min="0"
              max="360"
              class="form-control"
              @change="updateChart"
            />
          </div>
          <div class="mb-3">
            <label>
              {{ $t("insights.startAngle") }}
            </label>
            <input
              v-model="config.plotOptions.pie.endAngle"
              type="number"
              min="0"
              max="360"
              class="form-control"
              @change="updateChart"
            />
          </div>
        </div>
      </b-collapse>
    </div>
    <!------------ END: PIE OPTIONS ---------->
    <!------------ START: DONUT OPTIONS ---------->
    <div class="card border-right-0 border-left-0 border-top-0">
      <div class="card-header bg-white">
        <div v-b-toggle="'collapseDonut'" class="card-title">
          {{ $t("insights.customizeDonut") }}
        </div>
      </div>
      <b-collapse id="collapseDonut" accordion="element-accordion">
        <div class="card-body">
          <div class="mb-3">
            <div class="form-group">
              <label>
                {{ $t("insights.donutSize") }}
              </label>
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text">%</span>
                </div>
                <input
                  v-model="config.plotOptions.pie.donut.size"
                  type="number"
                  min="0"
                  max="100"
                  class="form-control"
                  @change="updateChart"
                />
              </div>
            </div>
          </div>
        </div>
      </b-collapse>
    </div>
    <!------------ END: DONUT OPTIONS ---------->
  </div>
</template>

<script>
export default {
  name: "PieCustomization",
  props: {
    config: {
      type: Object,
      default: () => {}
    },
    palettes: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {};
  },
  computed: {
    isDonut: {
      get: function () {
        return this.config.chart.type === "donut";
      },
      set: function (isDonut) {
        this.config.chart.type = isDonut ? "donut" : "pie";
      }
    }
  },
  mounted() {},
  methods: {
    updateChart() {
      this.$emit("update-chart");
    }
  }
};
</script>

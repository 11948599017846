<template>
  <div id="step-type">
    <div class="mb-5">
      <h5>{{ $t("insights.selectType") }}</h5>
    </div>
    <div class="row">
      <div v-for="(type, i) in types" :key="i" class="col-4">
        <div
          class="card card-custom card-type cursor-pointer"
          :class="{
            'border-primary': [hovering, selectedType].includes(type.name)
          }"
          @mouseenter="hovering = type.name"
          @mouseleave="hovering = ''"
          @click="selectedType = type.name"
        >
          <div class="card-body pb-0">
            <img
              class="img-fluid"
              :src="'media/charts/chart-type-' + type.name + '.png'"
              :alt="type.label"
              :title="type.label"
            />
          </div>
          <div class="card-footer p-3 border-top-0">
            <h5 class="mb-3 text-center">{{ type.label }}</h5>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    data: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      hovering: "",
      types: [
        {
          name: "line",
          label: this.$t("insights.typeLine")
        },
        {
          name: "area",
          label: this.$t("insights.typeArea")
        },
        {
          name: "column",
          label: this.$t("insights.typeColumn")
        },
        {
          name: "bar",
          label: this.$t("insights.typeBar")
        },
        {
          name: "pie",
          label: this.$t("insights.typePie")
        }
        // {
        //   name: "custom",
        //   label: this.$t("insights.typeCustom")
        // }
      ]
    };
  },
  computed: {
    selectedType: {
      get: function () {
        let type = this.data?.config?.chart.type ?? "";
        if (type === "bar") {
          let isHorizontal = this.data.config.plotOptions.bar.horizontal;
          type = isHorizontal ? "bar" : "column";
        } else if (type === "donut") {
          type = "pie";
        }
        return type;
      },
      set: function (newType) {
        this.data.config.chart.type = newType === "column" ? "bar" : newType;
        this.data.insightGraphs.forEach(graph => {
          graph.type = newType === "column" ? "bar" : newType;
        });
        this.data.config.plotOptions.bar.horizontal = newType === "bar";
      }
    }
  },
  mounted() {},
  methods: {
    isReady() {
      let ready = !!this.selectedType.length;
      if (!ready) {
        this.$toast.fire({
          icon: "warning",
          title: this.$t("insights.selectTypeWarning")
        });
      }
      return ready;
    }
  }
};
</script>

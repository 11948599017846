<template>
  <div class="card card-custom">
    <div class="card-header">
      <div class="card-title">{{ $t("insights.title") }}</div>
      <div class="card-toolbar">
        <button
          class="btn btn-secondary mr-1"
          @click="$router.push({ name: 'apexcharts' })"
        >
          {{ "Beispiel Vorschau" }}
        </button>
        <button class="btn btn-primary" @click="createVz">
          <i class="fal fa-plus" />
          {{ $t("insights.create") }}
        </button>
      </div>
    </div>
    <v-progress-linear v-if="isBusy" indeterminate color="primary" />
    <div class="card-body column">
      <TableWrapper :meta="meta" @reload-data="loadEntries">
        <b-table
          responsive
          :items="entries"
          :fields="fields"
          class="dataTable table table-head-custom"
          :show-empty="true"
          :empty-text="$t('table.noRecords')"
        >
          <template #cell(actions)="data">
            <button
              v-b-tooltip.top.noninteractive="$t('insights.show')"
              class="btn btn-icon btn-light btn-sm mr-1"
              @click="showInsight(data.item)"
            >
              <i class="fal fa-eye" />
            </button>
            <button
              v-b-tooltip.top.noninteractive="$t('insights.edit')"
              class="btn btn-icon btn-light btn-sm mr-1"
              @click="edit(data.item)"
            >
              <i class="fal fa-pen" />
            </button>
            <button
              v-b-tooltip.top.noninteractive="
                $t('insights.delete')
              "
              class="btn btn-icon btn-light btn-sm mr-1"
              @click="deleteInsight(data.item)"
            >
              <i class="fal fa-trash" />
            </button>
          </template>
        </b-table>
      </TableWrapper>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Insights from "@/components/Projects/Insights/insights";
import TableWrapper from "@/components/Tools/TableWrapper";
import { mapGetters } from "vuex";

export default {
  components: { TableWrapper },
  props: {},
  data() {
    return {
      entries: [],
      fields: [
        {
          key: "id",
          label: this.$t("table.id"),
          sortable: true
        },
        {
          key: "label",
          label: this.$t("table.label"),
          sortable: true
        },
        {
          key: "type",
          label: this.$t("insights.type"),
          sortable: true,
          formatter: (value, key, item) => this.getTypeName(item)
        },
        {
          key: "dataPipelines",
          label: this.$t("insights.dataPipelines"),
          sortable: true,
          formatter: (value, key, item) => this.pipelinesByGraph(item)
        },
        {
          key: "actions",
          label: this.$t("table.actions"),
          sortable: false,
          class: "text-right"
        }
      ],
      // Meta info
      meta: {},
      isBusy: false
    };
  },
  computed: {
    ...mapGetters("route", ["requestParams", "cancelToken"])
  },
  mounted() {
    this.setBreadcrumbs();
    this.loadEntries();
  },
  methods: {
    setBreadcrumbs() {
      this.$store.dispatch(SET_BREADCRUMB, [
        {
          title: this.$t("insights.title"),
          route: "/project/insights"
        }
      ]);
    },
    loadEntries() {
      this.isBusy = true;
      let params = this.requestParams();
      let cancelToken = this.cancelToken();
      Insights.getAll(params, cancelToken)
        .then(response => {
          this.entries = response.data.data;
          this.meta = response.data.meta;
          this.isBusy = false;
        })
        .catch(error => {
          this.$error(error);
          this.isBusy = this.axios.isCancel(error);
        });
    },
    createVz() {
      this.$router.push({ name: "projectInsightsCreate" });
    },
    edit(item) {
      this.$router.push({
        name: "projectInsightsEdit",
        params: { id: item.id }
      });
    },
    showInsight(item) {
      this.$router.push({
        name: "projectInsightsShow",
        params: { id: item.id }
      });
    },
    pipelinesByGraph(insight) {
      if (!insight) return "";
      let graphs = insight.insightGraphs;
      let string = "";
      graphs.forEach((graph, i) => {
        if (i > 0) {
          string += ", ";
        }
        string += graph.dataPipeline.name;
      });
      return string;
    },
    deleteInsight(item) {
      this.$swal
        .fire({
          icon: "warning",
          title: this.$t("insights.deleteTitle"),
          text: this.$t("insights.deleteText", {
            label: item.label
          }),
          showCancelButton: true,
          reverseButtons: true,
          confirmButtonColor: "#ff3554",
          cancelButtonText: this.$t("general.cancel"),
          confirmButtonText: this.$t("general.delete")
        })
        .then(result => {
          if (!result.isConfirmed) {
            return;
          }
          this.isBusy = true;
          Insights.delete(item.id)
            .then(() => {
              this.$toast.fire({
                icon: "success",
                title: this.$t("insights.deleteSuccess")
              });
              this.isBusy = false;
              this.loadEntries();
            })
            .catch(error => {
              this.$swal.fire({
                icon: "error",
                title: this.$t("general.caution"),
                text: error.response?.data?.message
              });
              this.isBusy = false;
            });
        });
    },
    getTypeName(item) {
      let type = item.config.chart.type;
      if (type === "bar") {
        let isHorizontal = item.config.plotOptions.bar.horizontal;
        type = isHorizontal ? "bar" : "column";
      }
      type = type.charAt(0).toUpperCase() + type.slice(1);
      return this.$t("insights.type" + type);
    }
  }
};
</script>


<template>
  <div class="card card-custom">
    <div class="card-header">
      <div class="card-title">{{ $t("insights.create") }}</div>
      <div class="card-toolbar">
        <button class="btn btn-secondary" @click="cancel">
          {{ $t("general.cancel") }}
        </button>
      </div>
    </div>
    <v-progress-linear v-if="isBusy" indeterminate color="primary" />
    <div class="card-body p-0">
      <InsightsWizard
        :insight="insight"
        @save="save"
      />
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import InsightsWizard from "@/components/Projects/Insights/Wizard/Index";
import Insights from "@/components/Projects/Insights/insights";

import { structure } from "@/components/Projects/Insights/structure";

export default {
  components: { InsightsWizard },
  props: {},
  data() {
    return {
      isCreated: false,
      insight: structure,
      isBusy: false
    };
  },
  mounted() {
    this.setBreadcrumbs();
  },
  methods: {
    setBreadcrumbs() {
      this.$store.dispatch(SET_BREADCRUMB, [
        {
          title: this.$t("insights.title"),
          route: "/project/insights/"
        },
        {
          title: this.$t("insights.create"),
          route: "/project/insights//create"
        }
      ]);
    },
    cancel() {
      this.$router.push({ name: "projectInsights" });
    },
    save(payload) {
      this.isCreated
        ? this.updateInsight(payload)
        : this.createInsight(payload);
    },
    updateInsight(payload) {
      this.isBusy = true;
      Insights.update(payload.data.id, payload.data)
        .then(() => {
          this.isBusy = false;
          payload.success();
        })
        .catch(error => {
          payload.error(error);
        });
    },
    createInsight(payload) {
      this.isBusy = true;
      Insights.store(payload.data)
        .then(response => {
          this.isCreated = true;
          this.isBusy = false;
          payload.success(response.data.data.id);
        })
        .catch(error => {
          payload.error(error);
          this.isBusy = false;
        });
    }
  }
};
</script>

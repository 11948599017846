<template>
  <div>
    <Create v-if="$route.name === 'projectInsightsCreate'" />
    <Show v-else-if="$route.name === 'projectInsightsShow'" />
    <Edit v-else-if="$route.name === 'projectInsightsEdit'" />
    <Table v-else />
  </div>
</template>

<script>
import Create from "@/components/Projects/Insights/Create";
import Show from "@/components/Projects/Insights/Show";
import Edit from "@/components/Projects/Insights/Edit";
import Table from "@/components/Projects/Insights/Table";

export default {
  components: {
    Create,
    Show,
    Edit,
    Table
  },
  props: {},
  data() {
    return {};
  },
  mounted() {},
  methods: {}
};
</script>

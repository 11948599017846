<template>
  <div>
    <div v-if="!show && !isBusy" class="text-center">
      <button
        class="btn btn-primary"
        :class="{ 'spinner spinner-white spinner-right': isBusy }"
        @click="getData"
      >
        {{ $t("insights.loadPreview") }}
      </button>
    </div>
    <div v-else-if="show">
      <button
        class="btn btn-primary mb-5"
        :class="{ 'spinner spinner-white spinner-right': isBusy }"
        @click="getData"
      >
        {{ $t("insights.reloadPreview") }}
      </button>
      <div v-if="showChart" class="row justify-content-center">
        <div class="col-xl-8 col-lg-10 col-12">
          <apexchart ref="chart" :options="config" :series="series" />
        </div>
      </div>
      <div id="accordionElements" class="accordion accordion-toggle-arrow px-1">
        <h5 class="my-5">
          {{ $t("insights.customizeOptions") }}
        </h5>
        <!--  Configs  -->
        <CustomizationLine
          v-if="chartType === 'line' || chartType === 'area'"
          :config="config"
          :palettes="palettes"
          @update-chart="updateChart"
        />
        <CustomizationBar
          v-else-if="chartType === 'bar'"
          :config="config"
          :palettes="palettes"
          @update-chart="updateChart"
        />
        <PieCustomization
          v-else-if="chartType === 'pie' || chartType === 'donut'"
          :config="config"
          :palettes="palettes"
          @update-chart="updateChart"
        />
      </div>
    </div>
    <div v-else>
      <div class="w-100 p-y20 d-flex justify-content-center">
        <button
          disabled
          class="btn btn-primary spinner spinner-right spinner-white"
          style="cursor: default"
        >
          {{ $t("general.loading") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Insights from "@/components/Projects/Insights/insights";

import CustomizationLine from "@/components/Projects/Insights/ChartTypes/Line/Customization";
import CustomizationBar from "@/components/Projects/Insights/ChartTypes/Bar/Customization";
import PieCustomization from "@/components/Projects/Insights/ChartTypes/Pie/Customization";

export default {
  components: {
    PieCustomization,
    CustomizationLine,
    CustomizationBar
  },
  props: {
    data: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      show: false,
      showChart: true,
      series: [],
      palettes: [
        {
          text: this.$t("insights.theme") + " 1",
          value: "palette1"
        },
        {
          text: this.$t("insights.theme") + " 2",
          value: "palette2"
        },
        {
          text: this.$t("insights.theme") + " 3",
          value: "palette3"
        },
        {
          text: this.$t("insights.theme") + " 4",
          value: "palette4"
        },
        {
          text: this.$t("insights.theme") + " 5",
          value: "palette5"
        },
        {
          text: this.$t("insights.theme") + " 6",
          value: "palette6"
        },
        {
          text: this.$t("insights.theme") + " 7",
          value: "palette7"
        },
        {
          text: this.$t("insights.theme") + " 8",
          value: "palette8"
        },
        {
          text: this.$t("insights.theme") + " 9",
          value: "palette9"
        },
        {
          text: this.$t("insights.theme") + " 10",
          value: "palette10"
        }
      ],
      stackTypes: [
        {
          text: this.$t("insights.stackTypeAbsolute"),
          value: "normal"
        },
        {
          text: this.$t("insights.stackTypeRelative"),
          value: "100%"
        }
      ],
      isBusy: false
    };
  },
  computed: {
    chartType: function () {
      return this.data?.config?.chart.type ?? "";
    },
    config: {
      get: function () {
        return this.data?.config ?? {};
      },
      set: function (config) {
        this.data.config = config;
      }
    }
  },
  async mounted() {
    await this.getData();
  },
  methods: {
    isReady() {
      return true;
    },
    async updateChart(stackOptionChanged = false) {
      this.$refs.chart.updateOptions(this.config);
      if (stackOptionChanged) {
        if (
          this.config.yaxis[0].stacked &&
          this.config.yaxis[0].stackType === "100%"
        ) {
          this.config.yaxis[0].max = 100;
          this.config.yaxis[0].min = 0;
        } else {
          this.config.yaxis[0].max = undefined;
          this.config.yaxis[0].min = undefined;
        }
        let me = this;
        setTimeout(function () {
          me.$refs.chart.updateOptions(me.config);
        }, 1000);
      }
    },
    async getData() {
      if (!this.data?.id) {
        return;
      }
      this.isBusy = true;
      await Insights.run(this.data.id)
        .then(response => {
          this.series = response.data.data.series;

          /*++++++++++++++++ Zwischenlösung bis Core angepasst ++++++++++++++++*/
          if (["pie", "donut"].includes(this.chartType)) {
            let series = [];
            let labels = [];
            this.series[0].data.forEach(data => {
              labels.push(data.x);
              series.push(data.y);
            });
            this.series = series;
            this.config.labels = labels;
          }
          /*++++++++++++++++  ++++++++++++++++* ++++++++++++++++*/

          this.show = true;
          this.isBusy = false;
        })
        .catch(error => {
          this.$swal.fire({
            icon: "error",
            title: this.$t("general.caution"),
            text: error.response?.data?.message
          });
        });
    }
  }
};
</script>

<template>
  <div>
    <div class="card card-custom mb-5">
      <div class="card-header">
        <div class="card-title">
          {{ $t("insights.commonSettings") }}
        </div>
      </div>
      <div class="card-body">
        <div class="mb-3">
          <label>
            {{ $t("insights.label") }}
          </label>
          <input
            v-model="$v.data.label.$model"
            :placeholder="$t('insights.label')"
            type="text"
            class="form-control"
            :class="{ 'is-invalid': $v.data.label.$error }"
          />
        </div>
      </div>
    </div>
    <div class="row align-items-stretch">
      <div
        v-for="(graph, i) in $v.graphs.$each.$iter"
        :key="i"
        class="col-xl-4 col-md-6 col-12"
      >
        <div class="card card-custom h-100">
          <div class="card-header">
            <div class="card-title">
              {{
                graph.label.$model
                  ? graph.label.$model
                  : $t("insights.graph") + " " + (i + 1)
              }}
            </div>
            <div class="card-toolbar">
              <button
                class="btn btn-icon text-hover-primary"
                @click="removeGraph(i)"
              >
                <i class="fal fa-trash" />
              </button>
            </div>
          </div>
          <div class="card-body">
            <div class="mb-3">
              <label>
                {{ $t("insights.graphLabel") }}
              </label>
              <input
                v-model="graph.label.$model"
                :placeholder="$t('insights.graphLabel')"
                type="text"
                class="form-control"
                :class="{ 'is-invalid': graph.label.$error }"
              />
            </div>
            <div class="mb-3">
              <label>
                {{ $t("insights.graphDataPipeline") }}
              </label>
              <v-select
                v-model="graph.data_pipeline_id.$model"
                :items="dataPipelines"
                item-text="name"
                item-value="id"
                :placeholder="$t('insights.graphDataPipeline')"
                :menu-props="{ offsetY: true }"
                class="form-control"
                :class="{ 'is-invalid': graph.data_pipeline_id.$error }"
                @input="loadDataPipelineFields"
                @change="clearXY(graph)"
              />
            </div>
            <LineConfig
              v-if="
                chartType === 'line' ||
                chartType === 'area' ||
                chartType === 'column' ||
                chartType === 'bar'
              "
              :key="configKey"
              :ref="'graph-config-' + i"
              :graph="graph.$model"
              :data-pipeline-fields="
                fieldsByDataPipeline(graph.data_pipeline_id.$model)
              "
            />
            <PieConfig
              v-else-if="chartType === 'pie' || chartType === 'donut'"
              :key="configKey"
              :ref="'graph-config-' + i"
              :graph="graph.$model"
              :data-pipeline-fields="
                fieldsByDataPipeline(graph.data_pipeline_id.$model)
              "
            />
          </div>
        </div>
      </div>
      <div v-if="graphs.length < maxGraphs" class="col-xl-4 col-md-6 col-12">
        <div class="card card-custom h-100">
          <div
            class="card-body text-dark-50 text-center text-hover-primary cursor-pointer d-flex align-items-center justify-content-center"
            @click="addGraph"
          >
            <div class="my-20">
              <i class="fal fa-plus-circle mb-3" style="font-size: 3rem" />
              <h4>
                {{ $t("insights.addGraph") }}
              </h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DataPipelines from "@/components/Projects/DataSets/dataPipelines";
import { required } from "vuelidate/lib/validators";

import LineConfig from "@/components/Projects/Insights/ChartTypes/Line/Config";
import PieConfig from "@/components/Projects/Insights/ChartTypes/Pie/Config";

export default {
  components: {
    PieConfig,
    LineConfig
  },
  props: {
    data: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      dataPipelines: [],
      graphs: [],
      xUnits: [
        { label: "insights.xUnitYears", value: "yyyy" },
        { label: "insights.xUnitMonthsText", value: "MMM" },
        { label: "insights.xUnitMonthsNumbers", value: "MM" },
        { label: "insights.xUnitDays", value: "dd" },
        { label: "insights.xUnitWeekdays", value: "ddd" },
        { label: "insights.xUnitHours24", value: "HH" },
        { label: "insights.xUnitHours12", value: "hh" }
      ],
      configKey: 1
    };
  },
  validations() {
    return {
      data: {
        label: { required }
      },
      graphs: {
        required,
        $each: {
          label: { required },
          data_pipeline_id: { required }
        }
      }
    };
  },
  computed: {
    maxGraphs: function () {
      let isPie = ["pie", "donut"].includes(this.data.config.chart.type);
      return isPie ? 1 : 2;
    },
    chartType: function () {
      return this.data?.config?.chart.type ?? "";
    }
  },
  beforeMount() {
    this.graphs = this.data.insightGraphs ?? [];
  },
  mounted() {
    this.loadDataPipelines();
  },
  methods: {
    isReady() {
      this.$v.$touch();
      console.log(this.$v)
      let ready = !this.$v.$invalid;
      this.graphs.forEach((graph, index) => {
        let name = "graph-config-" + index;
        let graphReady = this.$refs[name][0].isReady();
        if (!graphReady) ready = false;
      });
      if (!ready) {
        this.$toast.fire({
          icon: "warning",
          title: this.$t("insights.configurationWarning")
        });
      } else {
        this.$set(this.data, "insightGraphs", this.graphs);
      }
      return ready;
    },
    loadGraphsFields() {
      this.graphs.forEach(graph => {
        this.loadDataPipelineFields(graph.data_pipeline_id);
      });
    },
    loadDataPipelines() {
      const params = {
        page: 1,
        size: 999
      };
      DataPipelines.getAll(params)
        .then(response => {
          this.dataPipelines = response.data.data;
          this.loadGraphsFields();
        })
        .catch(error => {
          this.$swal.fire({
            icon: "error",
            title: this.$t("general.caution"),
            text: error.response?.data?.message
          });
        });
    },
    loadDataPipelineFields(id) {
      let dataPipeline = this.dataPipelines.find(dp => dp.id === id);
      let index = this.dataPipelines.indexOf(dataPipeline);
      if (dataPipeline.fields !== undefined) {
        return;
      }
      DataPipelines.fields(id)
        .then(response => {
          let fields = response.data.data;
          this.$set(this.dataPipelines[index], "fields", fields);
          this.configKey++;
        })
        .catch(error => {
          this.$swal.fire({
            icon: "error",
            title: this.$t("general.caution"),
            text: error.response?.data?.message
          });
        });
    },
    addGraph() {
      this.graphs.push({
        label: "",
        dataPipeline: undefined,
        x: undefined,
        y: undefined,
        type: this.data.config.chart.type
      });
    },
    removeGraph(index) {
      this.graphs.splice(index, 1);
    },
    fieldsByDataPipeline(id) {
      if (!id) return [];
      return this.dataPipelines.find(dp => dp.id === id)?.fields ?? [];
    },
    clearXY(graph) {
      graph.$model.x = undefined;
      graph.$model.y = undefined;
    }
  }
};
</script>
